/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    ColumnFilter, DtoModelResponse,
    GeneralStatus,
    PatientDto,
    SortType,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Patient<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Patient
   * @name PostPatient
   * @request POST:/Patient/Add
   */
  postPatient = (data: PatientDto, params: RequestParams = {}) =>
    this.request<DtoModelResponse<PatientDto>, any>({
      path: `/Patient/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Patient
   * @name LoadList
   * @request GET:/Patient/Load
   */
  loadList = (
    query?: {
      /** @format int32 */
      Take?: number;
      /** @format int32 */
      Skip?: number;
      IncludeIds?: number[];
      "OrderBy.Field"?: string;
      "OrderBy.Type"?: SortType;
      SearchTerm?: string;
      ColumnFilters?: ColumnFilter[];
      Status?: GeneralStatus;
      /** @format int64 */
      PracticeId?: number;
      ForDropdown?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<ListResponseModel<PatientDto>, any>({
      path: `/Patient/Load`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Patient
   * @name GetByIdList
   * @request GET:/Patient/GetById
   */
  getByIdList = (
    query?: {
      /** @format int64 */
      id?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<DtoModelResponse<PatientDto>, any>({
      path: `/Patient/GetById`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Patient
   * @name DeleteDelete
   * @request DELETE:/Patient/Delete
   */
  deleteDelete = (
    query?: {
      /** @format int64 */
      id?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<DtoModelResponse<PatientDto>, any>({
      path: `/Patient/Delete`,
      method: "DELETE",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Patient
   * @name UpdateCreate
   * @request POST:/Patient/Update
   */
  updateCreate = (data: PatientDto, params: RequestParams = {}) =>
    this.request<DtoModelResponse<PatientDto>, any>({
      path: `/Patient/Update`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
